import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout>
    <Seo title="About" />
		<div className="container">
			<p>
				This history has been compiled by Gordon Smith, Information Services Manager, Shotton, 1968-1997. His principal sources of information were: 1895-1996: Full Circle, the story of steelmaking on Deeside, written by Gordon Smith, published 1980; The Summers of Shotton, written by Brian Redhead and Sheila Gooddie, published 1987; A Century of Shotton Steel, written by Gordon Smith, published 1996; Steel News, the in-house news magazine of British Steel Corporation, 1967-1991;Coated Products Update and Corus Colors news magazines, 1991-2006; Management and Staff Pensioners’ reunion newsletters, 2004-2014. Tata Steel Shotton Business Update July 2013; European Coil Coating Association’s information web site on pre-painted metals, November 2013. 
			</p>
		</div>
  </Layout>
)

export default AboutPage
